/* your styles go here */
html {
  scroll-behavior: smooth;
}

.bg-abajo{
  /* background: url("../img/teesperamos.jpeg"); */
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  /* background-image: url("../img/teesperamos.jpeg"); */
  background-origin: padding-box;
  background-clip: border-box;
  background-size: cover;
  display: flex;
  justify-content: center;
  padding: 4rem 0;
}

.ir-arriba{
  display:none;
  background-repeat:no-repeat;
  font-size:20px;
  color:black;
  cursor:pointer;
  position:fixed;
  bottom:10px;
  right:10px;
  z-index:2;
}
/* FONT-RESPONSIVE */

/* If the screen size is 600px wide or less */
@media screen and (max-width: 500px) {  
h1 {
  font-size: 32px;
  line-height: 1;
  font-weight: 900;
  color: #f62948;
}
  
h2 {
  font-size: 16px;
  line-height: 1.7;
  font-weight: 300;
  color:#4b384a;
}

/* Backgrounds 600px wide or less */

.principal{
  /* background: url("../img/HERO1PHONE.jpg"); */
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100%;
  padding: 25rem 0; 
}

.bg-uva {
  /* background: url("../img/bannerxs.jpg"); */
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  /* background-image: url("../img/bannerxs.jpg"); */
  background-origin: padding-box;
  background-clip: border-box;
  background-size: cover;
  padding: 4rem 0;
}

.bg-dark {
  background: url("../img/fondogal.jpg");
   background-color: rgba(0, 0, 0, 0);
   background-repeat: repeat;
   background-image: url("../img/fondogal.jpg");
   background-size: contain;
   background-origin: padding-box;
   background-clip: border-box;
   padding: 4rem 0;
}

.nuestroshelados{
  background: url("../img/fondoblack.jpg");
  padding-top: 4rem ;
  padding-bottom: 4rem ;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-local {
  background: url("../img/local.jpg");
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-image: url("../img/local.jpg");
  background-size: cover;
  background-origin: padding-box;
  background-clip: border-box;
  padding: 4rem 0;
}
.bg-galeria {
  background: url("../img/fondosab.jpg");
    background-color: rgba(0, 0, 0, 0);
    background-repeat: repeat;
    background-image: url("../img/fondogal.jpg");
    background-size: contain;
    background-origin: padding-box;
    background-clip: border-box;
    padding-top: 4rem;
}
.img123 {
  margin-top: 0rem;
}}


/* If the screen size is betwenn 1200px and 501px wide */
@media screen and (max-width: 1024px) and (min-width: 501px) {



h1 {
    font-size: 32px;
    line-height: 1;
    font-weight: 900;
    color: #f62948;
}

h2 {
      font-size: 15px;
      line-height: 1.5;
      font-weight: 300;
      color:#4b384a;
}

/* Backgrounds betwenn 1200px and 501ps */

.principal{
  /* background: url("../img/HERO1IPAD.jpg"); */
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100%;
  padding: 23rem 0; 
}

.bg-dark {
  background: url("../img/fondogal.jpg");
   background-color: rgba(0, 0, 0, 0);
   background-repeat: repeat;
   background-image: url("../img/fondogal.jpg");
   background-size: contain;
   background-origin: padding-box;
   background-clip: border-box;
   padding: 4rem 0;
}

.nuestroshelados{
  background: url("../img/fondoblack.jpg");
  padding-top: 4rem ;
  padding-bottom: 4rem ;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-uva {
  /* background: url("../img/bannersm.jpg"); */
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  /* background-image: url("../img/bannersm.jpg"); */
  background-origin: padding-box;
  background-clip: border-box;
  background-size: cover;
  padding: 4rem 0;
}

.bg-local {
  background: url("../img/local.jpg");
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-image: url("../img/local.jpg");
  background-size: cover;
  background-origin: padding-box;
  background-clip: border-box;
  padding: 4rem 0;
}
.bg-galeria {
  background: url("../img/fondosab.jpg");
    background-color: rgba(0, 0, 0, 0);
    background-repeat: repeat;
    background-image: url("../img/fondogal.jpg");
    background-size: contain;
    background-origin: padding-box;
    background-clip: border-box;
    padding-top: 4rem;
}
.img123 {
  margin-top: 0rem;
}}

/* If the screen size is 1200px wide or more */




@media screen and (min-width: 1025px) {


  h1 {
  font-size: 40px;
  line-height: 1;
  font-weight: 900;
  color: #f62948;
}
h2 {
    font-size: 20px;
    line-height: 1.4;
    font-weight: 300;
    color:#4b384a;;
}}

/* FIN DEL FONT-RESPONSIVE */

/* MASONRY GALLERY*/

.masonryholder {
  column-gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  
}

.instagram {
  padding-top: 3rem 0;
  display: inline-block;
  width: 100%;
}

.gallery-block{
  padding-bottom: 60px;
  padding-top: 60px;
}

.gallery-block .heading{

    text-align: center;
}

.gallery-block .heading h2{
    font-weight: bold;
    font-size: 1.4rem;
    text-transform: uppercase;
}

.gallery-block.compact-gallery .item{
  overflow: hidden;
  margin-bottom: 0;
  background: #f62948;
  opacity: 1;
}

.gallery-block.compact-gallery .item .image{
  transition: 0.8s ease;
}

.gallery-block.compact-gallery .item .info{
  position: relative;
    display: inline-block;
}

.gallery-block.compact-gallery .item .description{
  display: grid;
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    padding: 10px;
    font-size: 17px;
    line-height: 18px;
    width: 100%;
    opacity: 1;
    color: #fff;
    transition: 0.8s ease;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.39));
}

.gallery-block.compact-gallery .item .description .description-heading{
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 80px;
}

@media (min-width: 576px) {

  .gallery-block.compact-gallery .item .description {
    opacity: 0; 
  }

  .gallery-block.compact-gallery .item a:hover .description {
    opacity: 1; 
  } 

  .gallery-block .zoom-on-hover:hover .image {
    transform: scale(1.3);
    opacity: 0.2; 
  }
}


.masonryblocks {
  display:block;
  margin: 0 0 15px;
  width: 100%;
  box-sizing: border-box;
}

.masonryblocks img{
  width: 100%;
}

@media screen and (max-width: 767px) {
  .masonryholder {
  column-count: 1;
}}


@media screen and (min-width: 768px) {
  .masonryholder {
  column-count: 2;
}}

@media screen and (min-width: 1080px) {
  .masonryholder {
  column-count: 4;
}}


@media screen and (min-width: 1200px) {
  .masonryholder {
  column-count: 4;
  }}

p { 
    margin-top: 1rem;
    margin-bottom: 0rem;
    color:#917e90;
    font-size: 15px;
}


/* ==========================================================================
 Features Section Start
 ========================================================================== */
 #features {
  background: #f6fbfd;
}

#features .feature-thumb {
  margin-top: 15px;
}

#features .nav {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  margin: 0 auto;
  padding: 0;
  max-width: 1200px;
}

#features .nav .nav-item {
  position: relative;
  z-index: 1;
  display: block;
  margin: 0;
  text-align: center;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

#features .nav .nav-item .nav-link {
  font-size: 20px;
  color: #a0a6ad;
  padding: 30px 0;
  line-height: 1;
  -webkit-transition: color 0.2s;
  -moz-transition: color 0.2s;
  transition: color 0.2s;
  border: none;
  background: transparent;
}

#features .nav .nav-item .nav-link i {
  font-size: 40px;
  margin-bottom: 5px;
  display: block;
  -webkit-transition: color 0.2s;
  -moz-transition: color 0.2s;
  transition: color 0.2s;
}

#features .nav-tabs {
  background: #f6fbfd;
  margin-top: -100px;
}

#features .nav-tabs .nav-item.show .nav-link,
#features .nav-tabs .nav-link.active {
  border-bottom: 1px solid #3c9cfd;
  color: #191c1e;
}

#features .nav-tabs .nav-item.show .nav-link i,
#features .nav-tabs .nav-link.active i {
  background: -webkit-linear-gradient(left, #4b384a 0%, #4b384a 100%);
  background: -o-linear-gradient(left, #4b384a 0%, #4b384a 100%);
  background: linear-gradient(to right, #4b384a 0%, #4b384a 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#features .tab-content {
  margin-top: 15px;
  text-align: center;
}

#features .tab-content .tab-pane {
  padding: 20px 15px;
  font-size: 14px;
  line-height: 26px;
}

.text-wrapper {
  height: 100%;
  width: 100%;
  display: table;
}

.text-wrapper > div {
  vertical-align: middle;
  display: table-cell;
}

.text-wrapper h3 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
}

.text-wrapper .btn-rm {
  margin-top: 15px;
}

/* ==========================================================================
  features-two
   ========================================================================== */
#features-two .img-thumb {
  margin-bottom: 15px;
}

.left, .right {
  cursor: pointer;
  font-size: 30px;
  color: #f62948;
}
/* ==========================================================================
    App Features Section Style
   ========================================================================== */
#app-features {
    background: url("../img/fondogal.jpg");
      background-color: rgba(0, 0, 0, 0);
      background-repeat: repeat;
      background-image: url("../img/fondogal.jpg");
      background-size: contain;
      background-origin: padding-box;
      background-clip: border-box;
      padding: 1rem 0;
  }

#app-features .content-left {
  position: relative;
}

#app-features .content-left span {
  float: right;
  margin-left: 25px;
}

#app-features .content-right {
  position: relative;
}

#app-features .content-right span {
  float: left;
  margin-right: 25px;
}

#app-features .box-item {
  margin: 30px 0;
}

#app-features .box-item .icon {
  text-align: center;
  margin: 0 15px;
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  text-align: center;
  position: relative;
  background-image: -moz-linear-gradient(0deg, #3d233c 0%, #3d233c 100%);
  background-image: -webkit-linear-gradient(0deg, #3d233c 0%, #3d233c 100%);
  z-index: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#app-features .box-item .icon i {
  color: #fff;
  font-size: 24px;
  line-height: 60px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#app-features .box-item .text h4 {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 15px;
  line-height: 22px;
}

#app-features .box-item .text p {
  font-size: 14px;
  line-height: 26px;
}

#app-features .content-left .text {
  margin-right: 90px;
}

#app-features .content-right .text {
  margin-left: 90px;
}

#app-features .show-box {
  text-align: center;
  margin: 15px 0;
}

#app-features .show-box img {
  width: 100%;
}
